<template>
  <div>
    <base-header class="pb-6 content__title content__title--calendar">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 mt-3 mt-lg-0 text-lg-right">
          <a
            href="#"
            @click.prevent="prev"
            class="fullcalendar-btn-prev btn btn-sm btn-default"
          >
            <i class="fas fa-angle-left"></i>
          </a>
          <a
            href="#"
            @click.prevent="next"
            class="fullcalendar-btn-next btn btn-sm btn-default"
          >
            <i class="fas fa-angle-right"></i>
          </a>
          <base-button
            class="btn btn-sm btn-default"
            @click="changeView('dayGridMonth')"
          >
            Month
          </base-button>
          <base-button
            class="btn btn-sm btn-default"
            @click="changeView('dayGridWeek')"
          >
            Week
          </base-button>
          <base-button
            class="btn btn-sm btn-default"
            @click="changeView('timeGridDay')"
          >
            Day
          </base-button>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <!-- Fullcalendar -->
          <div class="card card-calendar">
            <!-- Card header -->
            <div class="card-header">
              <!-- Title -->
              <h5 class="h3 mb-0">Calendar</h5>
            </div>
            <!-- Card body -->
            <div class="card-body p-0 card-calendar-body">
              <div id="fullCalendar"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal v-model:show="showAddModal" modal-classes="modal-secondary">
      <form class="new-event--form" @submit.prevent="saveEvent">
        <base-input
          name="title"
          label="Event title"
          placeholder="Event Title"
          :value="model.title"
          @input="(event) => (model.title = event.target.value)"
          input-classes="form-control-alternative new-event--title"
        >
        </base-input>
        <div class="form-group">
          <label class="form-control-label d-block mb-3">Status color</label>
          <div class="btn-group btn-group-toggle btn-group-colors event-tag">
            <label
              v-for="color in eventColors"
              :key="color"
              class="btn"
              :class="[color, { 'active focused': model.className === color }]"
            >
              <input
                v-model="model.className"
                type="radio"
                name="event-tag"
                :value="color"
                autocomplete="off"
              />
            </label>
          </div>
        </div>

        <base-input name="textarea" label="Description">
          <textarea
            v-model="model.description"
            class="form-control form-control-alternative edit-event--description textarea-autosize"
            placeholder="Event Desctiption"
          >
          </textarea>
          <i class="form-group--bar"></i>
        </base-input>

        <input type="hidden" class="new-event--start" />
        <input type="hidden" class="new-event--end" />
      </form>

      <template v-slot:footer>
        <button
          type="submit"
          class="btn btn-primary new-event--add"
          @click="saveEvent"
        >
          Add event
        </button>
        <button
          type="button"
          class="btn btn-link ml-auto"
          @click="showAddModal = false"
        >
          Close
        </button>
      </template>
    </modal>

    <modal v-model:show="showEditModal" modal-classes="modal-secondary">
      <form class="edit-event--form" @submit.prevent="editEvent">
        <base-input
          name="title2"
          label="Event title"
          placeholder="Event Title"
          :value="model.title"
          @input="(event) => (model.title = event.target.value)"
          input-classes="form-control-alternative new-event--title"
        >
        </base-input>
        <div class="form-group">
          <label class="form-control-label d-block mb-3">Status color</label>
          <div class="btn-group btn-group-toggle btn-group-colors event-tag">
            <label
              v-for="color in eventColors"
              :key="color"
              class="btn"
              :class="[color, { 'active focused': model.className === color }]"
            >
              <input
                v-model="model.className"
                type="radio"
                name="event-tag"
                :value="color"
                autocomplete="off"
              />
            </label>
          </div>
        </div>
        <base-input name="textarea" label="Description">
          <textarea
            v-model="model.description"
            class="form-control form-control-alternative edit-event--description textarea-autosize"
            placeholder="Event Desctiption"
          >
          </textarea>
          <i class="form-group--bar"></i>
        </base-input>
        <input type="hidden" class="new-event--start" />
        <input type="hidden" class="new-event--end" />
      </form>

      <template v-slot:footer>
        <base-button
          native-type="submit"
          type="primary"
          class="new-event--add"
          @click="editEvent"
          >Update</base-button
        >
        <base-button type="danger" @click="deleteEvent">Delete</base-button>
        <base-button type="link" class="ml-auto" @click="showEditModal = false"
          >Close</base-button
        >
      </template>
    </modal>
  </div>
</template>
<script>
import Modal from "@/components/Modal";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridDay from "@fullcalendar/timegrid";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const calenderRepository = RepositoryFactory.get("calender");

// const today = new Date();
// const y = today.getFullYear();
// const m = today.getMonth();
// const d = today.getDate();
var calendar;
export default {
  name: "calendar",
  components: {
    RouteBreadCrumb,
    Modal,
  },
  data() {
    return {
      events: [],
      model: {
        title: "",
        className: "bg-default",
        description: "",
        start: "",
        end: "",
      },
      showAddModal: false,
      showEditModal: false,
      eventColors: [
        "bg-info",
        "bg-orange",
        "bg-red",
        "bg-green",
        "bg-default",
        "bg-blue",
        "bg-purple",
        "bg-yellow",
      ],
    };
  },
  methods: {
    async initCalendar() {
      var calendarEl = document.getElementById("fullCalendar");

      calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin, timeGridDay, interactionPlugin],
        selectable: true,
        headerToolbar: false,
        select: (info) => {
          this.showAddModal = true;
          this.model.start = info.start;
          this.model.end = info.end;
        },
        eventClick: (event) => {
          this.model = {
            id: event.event._def.publicId,
            title: event.event._def.title,
            className: event.event._def.ui.classNames
              ? event.event._def.ui.classNames.join(" ")
              : "",
            start: event.event._instance.range.start,
            end: event.event._instance.range.end,
            description: event.event._def.extendedProps.description,
          };

          this.showEditModal = true;
        },
        events: this.events,
      });
      calendar.render();
    },
    changeView(newView) {
      calendar.changeView(newView);
      calendar.view.title;
    },
    next() {
      calendar.next();
    },
    prev() {
      calendar.prev();
    },
    async saveEvent() {
      if (this.model.title) {
        let event = {
          ...this.model,
          allDay: true,
        };
        this.events.push(JSON.parse(JSON.stringify(event)));

        event.end = event.end.toString();
        event.start = event.start.toString();

        let data = RepositoryFactory.JSON_to_URLEncoded(event, "data", []);
        let response = await calenderRepository.create(data);
        if (response.data.status == 100) {
          this.getCalendarData();
          this.isLoading = true;
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        }
      }
      this.model = {};
      this.events = [];
      this.showAddModal = false;
    },
    async editEvent() {
      if (this.model.title) {
        let event = {
          ...this.model,
          allDay: true,
        };
        this.events.push(JSON.parse(JSON.stringify(event)));

        event.end = event.end.toString();
        event.start = event.start.toString();

        let data = RepositoryFactory.JSON_to_URLEncoded(event, "data", []);
        let response = await calenderRepository.create(data);
        if (response.data.status == 101) {
          this.getCalendarData();
          this.isLoading = true;
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        }
      }
      this.model = {};
      this.events = [];
      this.showEditModal = false;
    },
    async deleteEvent() {
      if (this.model.title) {
        let event = {
          ...this.model,
          allDay: true,
        };
        this.events.push(JSON.parse(JSON.stringify(event)));

        event.end = event.end.toString();
        event.start = event.start.toString();

        let data = RepositoryFactory.JSON_to_URLEncoded(event, "data", []);
        let response = await calenderRepository.delete(data);
        if (response.data.status == 100) {
          this.getCalendarData();
          this.isLoading = true;
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        }
      }
      this.model = {};
      this.events = [];
      this.showEditModal = false;
    },
    async getCalendarData() {
      let data = await calenderRepository.getCalenderid();
      // this.events = data.data;

      if (data.data) {
        for (let index = 0; index < data.data.length; index++) {
          const element = data.data[index];
          this.events.push({
            id: element.id,
            title: element.title,
            className: element.className,
            description: element.description,
            start: new Date(element.start),
            end: new Date(element.end),
          });
        }
      }
      this.initCalendar();
    },
  },
  mounted() {
    this.getCalendarData();
    this.initCalendar();
  },
};
</script>
<style lang="scss">
@import "~@/assets/sass/core/vendors/fullcalendar";
.fc-direction-ltr .fc-daygrid-event .fc-event-time {
  margin-right: 3px;
  display: none;
}
.fc-event .fc-event-title {
  padding: 0.4rem 0.5rem;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  color: white;
}
</style>
